import * as React from "react"
import { graphql } from "gatsby"

import Layouttow from "../components/layouttow"
import Seo from "../components/seo"

import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
// import { GatsbyImage } from "gatsby-plugin-image"

const laboratory = ({ data, location }) => {
  // const siteTitle = data.site.siteMetadata.title

  return (
    <Layouttow location={location} title={"laucher-404"}>
      <Seo title={"laucher-404"} />
      <Container
        maxWidth="string"
        disableGutters
        sx={{
          maxWidth: "1068px",
          "@media (max-width: 600px)": {
            px: "1.5rem",
          },
        }}
      >
        <Typography>
          <img
            src="http://3650000.xyz/api/360.php?cid=6"
            style={{ borderRadius: "8px", marginTop: "10px" }}
            alt="404图片"
          />
          {/* <GatsbyImage
           image="http://3650000.xyz/api/360.php?cid=6"
           style={{ borderRadius: "8px", marginTop: "10px" }}
           alt="404图片"
           placeholder="blurred"  //  使用模糊占位符
           loading="lazy"  // 使用懒加载  
         /> */}
        </Typography>
      </Container>
    </Layouttow>
  )
}

export default laboratory

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
